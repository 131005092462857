import React, { useEffect, useState } from 'react';
import { PauseCircleFilled, PlayCircleFilled } from '@ant-design/icons';
import { globalHistory } from '@reach/router';
import './waveform.scss';

type WaveFormProps = {
  url: string;
  type: string;
};

const Waveform: React.FC<WaveFormProps> = ({ url, type }: WaveFormProps) => {
  const [playing, setPlaying] = useState(false);

  // This is the only way the compiler does not complain. Need to dig further to understand why it complains and how to fix it.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-undef
  const [waveForm, setWaveForm] = useState<WaveSurfer>(null);

  useEffect(() => {
    // Need to dynamically import here since SSR
    const WaveSurfer = require('wavesurfer.js');

    const waveSurfer = WaveSurfer.create({
      container: '#waveform',
      waveColor: '#9c9c9c',
      progressColor: '#000',
      cursorColor: 'var(--cta)',
      barWidth: 2,
      cursorWidth: 0,
      maxCanvasWidth: 200,
      barMinHeight: 0,
      normalize: true,
      height: 50,
      barGap: 3,
      responsive: true,
    });

    waveSurfer.on('finish', handleEnded);

    waveSurfer.load(url);

    setWaveForm(waveSurfer);

    globalHistory.listen(({ action }) => {
      action === 'PUSH' && waveSurfer.stop();
    });
  }, [url]);

  const handlePlay = () => {
    setPlaying(!playing);
    waveForm.playPause();
  };

  const handleEnded = (e?: React.SyntheticEvent<HTMLAudioElement, Event>) => {
    setPlaying(false);
  };

  return (
    <span className={'waveform__container'}>
      <button className={'waveform__play mr-2'} onClick={handlePlay}>
        {!playing ? (
          <PlayCircleFilled className={'waveform__play-svg'} />
        ) : (
          <PauseCircleFilled className={'waveform__play-svg'} />
        )}
      </button>
      <span id="waveform" className={'w-full h-auto z-10'} />
      <audio id="track">
        <source src={url} type={`audio/${type}`} />
      </audio>
    </span>
  );
};

export default Waveform;

import React, { useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import 'simple-react-notifications2/dist/index.css';
import { Landing } from './landing';
import { About } from './about';
import { Contact } from './contact';
import Layout from '../components/layout';
import SEO from '../components/seo';
import './index.scss';
import './contact.scss';
import { PageId } from '../modules/routing';
import { StaticImage } from 'gatsby-plugin-image';
import { offsetScroll } from '../tools/offsetScrollTo';

const HomePage: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    const hash = window.location.hash;
    window.scroll(0, 0);
    offsetScroll(hash.substr(1) as PageId);
  });

  return (
    <Layout>
      <SEO title={'Home'} />
      <section id={PageId.HOME}>
        <Landing />
      </section>
      <section
        className={
          'flex flex-wrap flex-wrap-reverse gap-16 justify-center items-center p-8 md:py-20 lg:py-40 bg-lightest-gray'
        }
        id={PageId.ABOUT}
      >
        <StaticImage
          src={'../assets/images/elaine-ps.jpg'}
          placeholder="blurred"
          layout="fullWidth"
          className={'rounded'}
          style={{ width: '350px' }}
          alt={'Elaine smiling'}
        />
        <About />
      </section>
      <section
        className={'px-8 pt-8 pb-4 md:py-10 lg:py-20'}
        id={PageId.CONTACT}
      >
        <div className={'flex flex-wrap gap-20 justify-center items-center'}>
          <Contact />
          <StaticImage
            src={'../assets/images/elaine-mic-ps.jpg'}
            placeholder="blurred"
            layout="fullWidth"
            className={'rounded'}
            style={{ width: '576px' }}
            alt={'Elaine recording in front of a microphone'}
          />
        </div>
      </section>
    </Layout>
  );
};

export default HomePage;

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
// import { navigate, RouteComponentProps } from '@reach/router';
import { RouteComponentProps } from '@reach/router';
import { StaticImage } from 'gatsby-plugin-image';
import 'simple-react-notifications2/dist/index.css';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Waveform from '../components/waveform';
// import Audio from '../components/audio';

export const Landing: React.FC<RouteComponentProps> = () => {
  const { sampleFile } = useStaticQuery(graphql`
    query IndexQuery {
      sampleFile: file(name: { eq: "sample" }) {
        publicURL
        name
        extension
      }
    }
  `);

  return (
    <div className="flex flex-row w-full items-start mb-20 xl:m-0  min-w-0 landing-page__hero-wrapper">
      <div className={'landing-page__hero'}>
        <div className={'landing-page__hero--inner'}>
          <h1 className={'landing-page__hero-h1'}>
            <span>
              The voice <br />
              that works <br />
              for you.
            </span>
          </h1>
          <div className={'mt-5'}>
            <Waveform url={sampleFile.publicURL} type={sampleFile.extension} />
            {/*<Audio url={sampleFile.publicURL} type={sampleFile.extension} />*/}
          </div>
          {/*<div className={'landing-page__actions'}>*/}
          {/*  <button*/}
          {/*    onClick={() => navigate('/contact/')}*/}
          {/*    className="btn btn-cta"*/}
          {/*  >*/}
          {/*    Demo!*/}
          {/*  </button>*/}
          {/*</div>*/}
        </div>
      </div>
      <div className={'absolute right-0'}>
        <StaticImage
          src={'../assets/images/mic.png'}
          placeholder="blurred"
          layout="fullWidth"
          className={'cover__photo'}
          alt={'mic'}
        />
      </div>
    </div>
  );
};

const LandingPage: React.FC<RouteComponentProps> = () => {
  return (
    <Layout>
      <SEO title={'Elaine Mays'} />
      <Landing />
    </Layout>
  );
};

export default LandingPage;
